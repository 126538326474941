import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../layouts"

class Social extends Component {
  constructor(props) {
    super(props)
    this.state = {
      articles: [],
      initialLoad: false,
      loading: true,
      categories: [],
      activeTag: "",
      activeCategory: ""
    }
    
    
  }
  componentDidMount() {
    this.setState({
      loading: true
    });
    // this.fetchResults()
  }
  render() {
    const data = this.props.data
    


    return (
        <Layout page="news">
          <section className="section news news--social">
            <div className="container">
              <div className="news__social">
                <h1>@VisitFilms on Social</h1>
                <div id="curator-feed-news-layout"><a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a></div>
              </div>
              
            </div>
          </section> 
          <></>
          <section className="section news">
            
          </section>
      </Layout>
    )
  }
}

export default Social

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
  
      site {
        siteMetadata {
          apiUrl
        }
      }
    }
    
`
